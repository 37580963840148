@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Anuphan:wght@100;200;300;400;500;600&family=Inter:wght@400;600;700&family=Outfit:wght@600&family=Poppins:wght@100;300;400;500&display=swap');


body{
    font-family: 'Anuphan', sans-serif;
    /* font-family: 'Inter', sans-serif;
    font-family: 'Outfit', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Poppins', sans-serif;*/
}



.grey-bg{
    background: "#F0F0F0";
}

.green{
    color: "#A6F200";
}

.blue-bg{
    background: "#003249";
}


.grey-text{
    color: "#505155";
}

/* add transition to all button */
button {
    transition: all 0.3s ease-in-out 0s;
}

input {
    transition: all 0.3s ease-in-out 0s;
}